@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

html,
body {
  font-family: "Open Sans", sans-serif;
  @apply bg-gradient-to-tr from-indigo-500 via-purple-500 to-pink-500 bg-center bg-fixed bg-no-repeat bg-cover text-white;
}

.typewriter {
  font-family: monospace;
}

h1,
h2 {
  @apply font-bold;
}

h1 {
  @apply text-3xl md:text-4xl lg:text-5xl;
}

h2 {
  @apply text-2xl lg:text-3xl;
}

section {
  @apply relative p-10;
}

.emoji {
  @apply text-5xl hidden sm:block;
}

.image {
  @apply w-64 h-64 xl:w-96 xl:h-96;
}

a:hover {
  @apply underline;
}

.dropping-binary {
  @apply absolute;
  transition-property: opacity top;
  transition-duration: 5s;
}

section:first-of-type {
  min-height: calc(100vh - 68px);
}

.window {
  @apply rounded-md bg-white w-full max-w-screen-lg;

  .menu-bar {
    @apply flex gap-x-4 items-center bg-[#dfe1e5] py-2 px-5 rounded-t-md text-neutral-800;

    .buttons {
      @apply flex gap-x-2;

      &:hover {
        > div {
          @apply !text-black/50;
        }
      }

      > div {
        @apply w-3 h-3 border border-black border-opacity-5 flex items-center justify-center transition-colors;
        border-radius: 999px;

        &:nth-child(1) {
          @apply text-2xs;
          color: #ff605c;
          background-color: #ff605c;
        }

        &:nth-child(2) {
          color: #ffbd44;
          background-color: #ffbd44;
        }

        &:nth-child(3) {
          @apply text-3xs;
          color: #00ca4e;
          background-color: #00ca4e;
        }
      }
    }

    .tabs {
      @apply flex text-neutral-700 items-center;
      > div {
        @apply py-2 px-3 flex items-center gap-x-2 relative text-xs -mb-2 border-r;

        &:not(:first-of-type) {
          @apply hidden md:flex;

          &:after {
            @apply border-r border-neutral-400 h-2/3 absolute right-0;
            content: " ";
          }
        }

        &:first-of-type {
          @apply bg-white rounded-t-lg;

          &:before {
            @apply -left-full pointer-events-none absolute block h-4/5 w-full z-10 visible;
            content: " ";
            border-radius: 0 0 10px 0;
            box-shadow: 14px 0.25em 0 -4px white;
            bottom: -1px;
          }

          &:after {
            @apply pointer-events-none absolute block h-4/5 w-full z-10 visible -right-full opacity-100;
            content: " ";
            border-radius: 0 0 0 10px;
            box-shadow: -14px 0.25em 0 -4px white;
            bottom: -1px;
          }
        }
      }

      > svg {
        @apply ml-1 mt-0.5 text-sm hover:bg-neutral-300 py-1.5 px-2 rounded-full -mb-1 transition-colors duration-300;
      }
    }
  }

  .url-bar {
    @apply flex gap-x-4 py-2 pl-4 pr-2 text-base items-center;

    > svg {
      &:not(.disabled) {
        @apply text-neutral-500 hover:bg-neutral-100 py-1.5 px-1.5 rounded-full transition-colors duration-200;
      }

      &.disabled {
        @apply text-neutral-300;
      }
    }

    > div {
      @apply bg-neutral-100 py-2 px-4 rounded-full flex-grow -my-1 flex gap-x-3 items-center text-xs min-w-0;

      > div {
        @apply overflow-hidden text-ellipsis;
      }

      > svg {
        @apply text-neutral-500;
      }
    }
  }

  .bookmarks {
    @apply hidden sm:flex pr-4 pl-2 border-b border-b-neutral-200 text-base items-center text-neutral-700 pb-0.5 min-w-0;

    div {
      @apply flex items-center text-xs py-0.5 pl-2 pr-1 rounded-full hover:bg-neutral-100 cursor-default transition-colors whitespace-nowrap overflow-hidden text-ellipsis;
    }
  }

  .content {
    @apply p-4;
  }
}

.tree {
  @apply max-w-screen-xl w-full;

  div {
    @apply w-full text-center;
  }

  children {
    @apply flex mt-12 justify-between;
  }

  li {
    @apply block;
  }

  code {
    @apply w-full border-2 border-black p-2 rounded-md;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}

@keyframes mail {
  0% {
    left: -259px;
    top: 14px;
  }

  100% {
    left: -214px;
    top: 59px;
  }
}

.mail-animation {
  animation: mail 5s linear infinite;
}

.wave-animation {
  transform-origin: bottom right;
  animation: wave-hand 1500ms ease-in-out infinite;
}

/* @keyframes wave-hand {
  0% {
    transform: rotate(0deg);
  }

  5% {
    transform: rotate(15deg);
  }

  15% {
    transform: rotate(-5deg);
  }

  20% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
} */

@keyframes wave-hand {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
